export const newSurchargePages = 500;
export const newSurchargePrice = 0.5;
export const termsServiceURL = "https://itm21st.com/terms-of-service/";

export const pricingTypes = {
    Standard: 1,
    Expedited: 2,
    Tertiary: 11,
    ExpeditedTertiary: 20,
    DoctorReview: 21,
    Simplified: 22,
    Consultation: 24,
    ExpeditedConsultation: 25,
    ConsultationDoctorReview: 29,
    Cdr: 35
};

export const bulkLeSearchClients = {
    coventry: 8,
    itmSample: 28
};

export const states = [
    { state: "AK", statelong: "Alaska" },
    { state: "AL", statelong: "Alabama" },
    { state: "AR", statelong: "Arkansas" },
    { state: "AZ", statelong: "Arizona" },
    { state: "CA", statelong: "California" },
    { state: "CO", statelong: "Colorado" },
    { state: "CT", statelong: "Connecticut" },
    { state: "DC", statelong: "Washington DC" },
    { state: "DE", statelong: "Delaware" },
    { state: "FL", statelong: "Florida" },
    { state: "GA", statelong: "Georgia" },
    { state: "HI", statelong: "Hawaii" },
    { state: "IA", statelong: "Iowa" },
    { state: "ID", statelong: "Idaho" },
    { state: "IL", statelong: "Illinois" },
    { state: "IN", statelong: "Indiana" },
    { state: "KS", statelong: "Kansas" },
    { state: "KY", statelong: "Kentucky" },
    { state: "LA", statelong: "Louisianna" },
    { state: "MA", statelong: "Massachusetts" },
    { state: "MD", statelong: "Maryland" },
    { state: "ME", statelong: "Maine" },
    { state: "MI", statelong: "Michigan" },
    { state: "MN", statelong: "Minnesota" },
    { state: "MO", statelong: "Missouri" },
    { state: "MS", statelong: "Mississippi" },
    { state: "MT", statelong: "Montana" },
    { state: "NC", statelong: "North Carolina" },
    { state: "ND", statelong: "North Dakota" },
    { state: "NE", statelong: "Nebraska" },
    { state: "NH", statelong: "New Hampshire" },
    { state: "NJ", statelong: "New Jersey" },
    { state: "NM", statelong: "New Mexico" },
    { state: "NV", statelong: "Nevada" },
    { state: "NY", statelong: "New York" },
    { state: "OH", statelong: "Ohio" },
    { state: "OK", statelong: "Oklahoma" },
    { state: "OR", statelong: "Oregon" },
    { state: "PA", statelong: "Pennsylvania" },
    { state: "PR", statelong: "Puerto Rico" },
    { state: "RI", statelong: "Rhode Island" },
    { state: "SC", statelong: "South Carolina" },
    { state: "SD", statelong: "South Dakota" },
    { state: "TN", statelong: "Tennessee" },
    { state: "TX", statelong: "Texas" },
    { state: "UT", statelong: "Utah" },
    { state: "VA", statelong: "Virginia" },
    { state: "VI", statelong: "Virigin Islands" },
    { state: "VT", statelong: "Vermont" },
    { state: "WA", statelong: "Washington" },
    { state: "WI", statelong: "Wisconsin" },
    { state: "WV", statelong: "West Virginia" },
    { state: "WY", statelong: "Wyoming" }
];
